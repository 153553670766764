import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { AlertPriorActivityResponse } from 'app/modules/alerts/types/responses';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';
import { selectNewAlertDataAPIEnabled } from 'app/shared/featureFlags/selectors';

export const useGetAlertPriorActivity = (alertId: number) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const hasNewAlertDataAPI = useSelector(selectNewAlertDataAPIEnabled);

  return useQuery({
    staleTime: 20 * 60 * 1000, // 20min
    enabled: hasNewAlertDataAPI && hasReadAlertsPermission && alertId > 0,
    queryKey: ALERT_QUERY_KEYS.getAlertPriorActivity(alertId),
    queryFn: () =>
      post<AlertPriorActivityResponse>('/alert/prior-activity', {
        alert_id: alertId,
      } satisfies {
        alert_id: number;
      }),
  });
};
