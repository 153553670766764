import { cloneDeep } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Styles
import palette from 'vendor/material-minimal/palette';

// Const
import {
  ConnectionNodeDataDefinition,
  EntityNodeDataDefinition,
  GlyphNodeDataDefinition,
  EntityGraphLink,
  WidgetProps,
} from 'app/modules/detectionModels/components/scenarioWidgets/models';
import { EmbeddedFilters } from 'app/modules/detectionModels/models';
import {
  FIELD_TO_MATCHING_ATTRIBUTES,
  FUZZY_MATCHABLE_FIELDS,
  GraphNodeFilterEditorModal,
  QUERY_CONFIG_MAP,
} from 'app/modules/detectionModels/components/scenarioWidgets/GraphNodeFilterEditorModal';
import { useSelector } from 'react-redux';
import {
  U21NetworkGraph,
  U21NetworkGraphElements,
  U21NetworkGraphProps,
} from 'app/shared/components/Graphs/U21NetworkGraph';
import { EventObject, Position } from 'cytoscape';
import {
  GBR_ELEMENT_KEYS_TO_UPDATE,
  GBR_LAYOUT_OPTIONS,
  GRAPH_BASED_RULES_CYTOSCAPE_OPTIONS,
} from 'app/modules/detectionModels/components/scenarioWidgets/constants';
import { selectCustomDataSettingsByClassifier } from 'app/modules/dataSettings/selectors';
import {
  CustomDataSettingsConfigResponse,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import { entityLinkToCustomDataKey } from 'app/modules/detectionModels/components/scenarioWidgets/helpers';
import { zoom } from 'app/shared/components/Graphs/utils';
import { selectGbrFuzzyEnabled } from 'app/shared/featureFlags/selectors';
import { formatNodeLabel } from 'app/modules/detectionModels/components/scenarioWidgets/entityLinkCount/utils';

interface Props {}

type AllProps = Props & WidgetProps;

const MIN_LINK_COUNT = 1;
const MAX_LINK_COUNT = 50;

interface GetGlyphParams {
  hasFilter: boolean;
  canFilter: boolean;
  isOriginalConnection: boolean;
  canAddOrRemove?: boolean;
  hasCustomGrouping?: boolean;
  canCustomGroup?: boolean;
  parentId: string;
  canFuzzyMatch: boolean;
}

const getGlyphs = ({
  hasFilter,
  canFilter,
  isOriginalConnection,
  canAddOrRemove,
  hasCustomGrouping,
  canCustomGroup,
  parentId,
  canFuzzyMatch,
}: GetGlyphParams): GlyphNodeDataDefinition[] | undefined => {
  if (!canFilter && !canAddOrRemove && !canFuzzyMatch) {
    return undefined;
  }
  const glyphs: GlyphNodeDataDefinition[] = [];
  if (canFilter || canFuzzyMatch) {
    glyphs.push({
      id: `glyph-${parentId}-canFilter`,
      nodeType: 'glyph',
      pos: 'ne',
      icon: hasFilter ? 'filter' : 'filterOff',
      color: hasFilter
        ? palette.dark.colors.green.dark
        : palette.dark.colors.grey.dark,
    });
  }
  if (canAddOrRemove) {
    glyphs.push({
      id: `glyph-${parentId}-canAddOrRemove`,
      nodeType: 'glyph',
      pos: 'nw',
      icon: isOriginalConnection ? 'plus' : 'minus',
      color: isOriginalConnection
        ? palette.dark.colors.green.dark
        : palette.dark.colors.red.dark,
    });
  }
  if (canCustomGroup) {
    glyphs.push({
      id: `glyph-${parentId}-canCustomGroup`,
      nodeType: 'glyph',
      pos: 'n',
      icon: hasCustomGrouping ? 'folders' : 'foldersOff',
      color: hasCustomGrouping
        ? palette.dark.colors.green.dark
        : palette.dark.colors.grey.dark,
    });
  }
  return glyphs;
};

const buildNodeData: (params: {
  numConnections: number;
  connectionTypes: string[];
  filters: EmbeddedFilters;
  viewOnly?: boolean;
  customDataSettings: Record<
    Unit21DataClassifier,
    CustomDataSettingsConfigResponse[]
  >;
  gbrFuzzyEnabled: boolean;
}) => U21NetworkGraphElements = ({
  numConnections,
  connectionTypes,
  filters,
  viewOnly,
  customDataSettings,
  gbrFuzzyEnabled,
}) => {
  const elements: U21NetworkGraphElements = { nodes: {}, edges: {} };
  const connectionIds: string[] = [];
  // construct connection node data
  for (const connection of connectionTypes) {
    const [connectionType, num] = connection.split(':') as [
      EntityGraphLink,
      string?,
    ];

    const existingFilter = filters?.[connection];
    const configKey = entityLinkToCustomDataKey(connectionType);

    const isOriginalConnection = num === undefined;
    const canFuzzyMatch =
      gbrFuzzyEnabled && FUZZY_MATCHABLE_FIELDS.has(connectionType);

    const canFilter =
      !!QUERY_CONFIG_MAP[connectionType] ||
      connectionType === 'entity_custom' ||
      canFuzzyMatch;

    // Only show glyph here if we can actually add some filters
    const numText: string = num ? `:${num}` : '';
    const hasCustomDataGrouping =
      !!configKey &&
      !!customDataSettings[configKey] &&
      !!customDataSettings[configKey].length;
    const canCustomGroup =
      !!FIELD_TO_MATCHING_ATTRIBUTES[connectionType] || hasCustomDataGrouping;
    // Bank accounts can be added/removed, but not filtered.
    // entity_custom can't be added/removed since any filters will be applied to all the entities
    // and we already allow users to select multiple grouping fields which is the same functionality
    const canAddOrRemove =
      (!viewOnly &&
        (canFilter || connectionType === 'bank_account') &&
        connectionType !== 'entity_custom' &&
        canFuzzyMatch &&
        connectionType === 'email') ||
      (!canFuzzyMatch && canFilter);
    const connectionId = `connection-${connectionType}-${numText}`;
    const glyphs: GlyphNodeDataDefinition[] | undefined = getGlyphs({
      hasFilter: !!existingFilter?.raw_sql,
      canFilter,
      isOriginalConnection,
      canAddOrRemove,
      canCustomGroup,
      hasCustomGrouping: existingFilter?.grouping !== undefined,
      parentId: connectionId,
      canFuzzyMatch,
    });
    const nodeData: ConnectionNodeDataDefinition = {
      id: connectionId,
      nodeType: 'connection',
      degree: 2,
      label: formatNodeLabel(
        connectionType,
        existingFilter,
        configKey,
        customDataSettings,
        numText,
        canFuzzyMatch,
        gbrFuzzyEnabled,
      ),
      color: palette.light.grey[600],
      glyphs,
      connectionType,
      canFilter,
      canAddOrRemove,
      isOriginalConnection,
      fullConnectionName: connection,
    };
    elements.nodes[connectionId] = { data: nodeData };
    connectionIds.push(connectionId);
  }

  const numConn = Math.min(numConnections, MAX_LINK_COUNT);
  // construct entity node data
  for (let i = 0; i < numConn; i++) {
    const userColor = palette.light.colors.yellow.main;
    const userNum = i === 0 ? '' : `:${i}`;
    const fullConnectionName = `entity:${i}`;

    const entityFilter = filters?.[fullConnectionName];
    const {
      inclusion_tag_names: inclusionTagNames,
      exclusion_tag_names: exclusionTagNames,
    } = entityFilter ?? {};
    let inclusionTags: string = '';
    let exclusionTags: string = '';
    const inclusionTagsDisplay = (tagNames) =>
      `\nInclude tags: ${tagNames.join(', ')}`;
    if (inclusionTagNames?.length) {
      inclusionTags = inclusionTagsDisplay(inclusionTagNames);
    }
    const exclusionTagsDisplay = (tagNames) =>
      `\nExclude tags: ${tagNames.join(', ')}`;
    if (exclusionTagNames?.length) {
      exclusionTags = exclusionTagsDisplay(exclusionTagNames);
    }

    const userQuery = !entityFilter?.raw_sql ? '' : `\n${entityFilter.raw_sql}`;
    const userLabel = `Entity${userNum}${userQuery}${inclusionTags}${exclusionTags}`;
    const entityId = `entity-${i}`;
    const entityGlyphs: GlyphNodeDataDefinition[] | undefined = getGlyphs({
      hasFilter: entityFilter !== undefined,
      canFilter: true,
      isOriginalConnection: false,
      canAddOrRemove: false,
      parentId: entityId,
      canFuzzyMatch: false,
    });
    const nodeData: EntityNodeDataDefinition = {
      id: entityId,
      nodeType: 'entity',
      degree: 1,
      label: userLabel,
      color: userColor,
      glyphs: entityGlyphs,
      num: i,
      fullConnectionName,
    };
    elements.nodes[entityId] = { data: nodeData };

    // create edge between entity and each connection
    for (const connectionId of connectionIds) {
      const edgeId = `edge-${connectionId}-${entityId}`;
      elements.edges[edgeId] = {
        data: { id: edgeId, source: connectionId, target: entityId },
      };
    }
  }
  return elements;
};

const getGlyphPosition = (
  pos: GlyphNodeDataDefinition['pos'],
  coords: Position,
): Position => {
  const { x, y } = coords;
  switch (pos) {
    case 'nw':
      return { x: x - 12, y: y - 12 };
    case 'ne':
      return { x: x + 12, y: y - 12 };
    case 'n':
    default:
      return { x, y: y - 16 };
  }
};

const onLayoutEnd: U21NetworkGraphProps['onLayoutEnd'] = ({ cy }) => {
  cy.nodes('node[nodeType = "glyph"]').remove();
  const nodes = cy.nodes();
  for (const node of nodes) {
    const glyphs = node.data('glyphs');
    const coords = node.position();
    if (glyphs) {
      for (const g of glyphs as GlyphNodeDataDefinition[]) {
        const { pos, ...rest } = g;
        cy.add({
          data: { ...rest },
          position: getGlyphPosition(pos, coords),
        });
      }
    }
  }
  if (cy.zoom() !== 1) {
    return;
  }
  if (nodes.length < 4) {
    zoom(cy, 1.5);
  } else if (nodes.length < 5) {
    zoom(cy, 1.3);
  }
};

export const getFilteredLinkTypes = ({
  allLinks,
  filters,
  numLinks,
}: {
  allLinks: string[];
  filters: EmbeddedFilters;
  numLinks: number;
}): {
  filteredLinks: string[];
  filteredFilters: EmbeddedFilters;
  filtered: boolean;
} => {
  const linksSet = new Set(allLinks);
  const filteredLinks = allLinks.filter((value) => {
    const [type] = value.split(':');
    // Only add this type if the base type is still in the list of links. E.g if the `phone` link is remove so should `phone:<n>`
    return linksSet.has(type);
  });
  const clonedFilters = cloneDeep(filters);
  let filtered = allLinks.length !== filteredLinks.length;

  for (const key of Object.keys(clonedFilters)) {
    const [type, num] = key.split(':');
    if (type === 'entity') {
      // Entity filter is no longer needed. remove
      if (Number.parseInt(num, 10) > numLinks) {
        filtered = true;
        delete clonedFilters[key];
      }
    } else if (
      key !== 'num_links' &&
      key !== 'link_type' &&
      key !== 'entity.ONLYUSERKEYS' &&
      (!linksSet.has(key) || !linksSet.has(type))
    ) {
      filtered = true;
      delete clonedFilters[key];
    }
  }
  return { filteredLinks, filteredFilters: clonedFilters, filtered };
};

interface SelectedConnectionInfo {
  connectionType: EntityGraphLink;
  fullName: string;
}

const DEFAULT_REAL_FILTERS = {};

export const EntityLinkCountMockGraph = (props: AllProps) => {
  const customDataSettings = useSelector(selectCustomDataSettingsByClassifier);
  const gbrFuzzyEnabled = useSelector(selectGbrFuzzyEnabled);
  const { editingScenario, onChange, viewOnly } = props;
  const { embeddedFilters, filters } = editingScenario;
  const realFilters: EmbeddedFilters =
    embeddedFilters ?? filters ?? DEFAULT_REAL_FILTERS;
  const [selectedNode, setSelectedNode] = useState<
    SelectedConnectionInfo | undefined
  >();
  const [connectionTypeCounts, setConnectionTypeCounts] = useState<{
    [key: string]: number;
  }>({});
  const numLinksStr: string = editingScenario.parameters.num_links as string;
  const numLinks: number = numLinksStr
    ? Number.parseInt(numLinksStr, 10)
    : MIN_LINK_COUNT;

  const numConnections = numLinks + 1;
  // Needed check for backwards compatibility reasons when link type was a single link
  const connectionTypes: string[] = editingScenario.parameters.link_type;

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  useEffect(() => {
    if (isNaN(numLinks)) {
      const newScenario = cloneDeep(editingScenario);
      newScenario.parameters.num_links = MIN_LINK_COUNT.toString();
      onChangeRef.current(newScenario);
      return;
    } else if (numLinks > MAX_LINK_COUNT) {
      // Force UI to only allow MAX_LINK_COUNT for now
      const newScenario = cloneDeep(editingScenario);
      newScenario.parameters.num_links = MAX_LINK_COUNT.toString();
      onChangeRef.current(newScenario);
      return;
    } else if (numLinks < MIN_LINK_COUNT) {
      // Force UI to have a minimun number of links
      const newScenario = cloneDeep(editingScenario);
      newScenario.parameters.num_links = MIN_LINK_COUNT.toString();
      onChangeRef.current(newScenario);
      return;
    }
    const { filteredLinks, filteredFilters, filtered } = getFilteredLinkTypes({
      allLinks: connectionTypes,
      filters: realFilters,
      numLinks,
    });
    // We have removed a link, update the scenario
    if (filtered) {
      const newScenario = cloneDeep(editingScenario);
      newScenario.parameters.link_type = filteredLinks;
      // Filtering filters just to be safe and remove unnecessary filters
      newScenario.embeddedFilters = filteredFilters;
      onChangeRef.current(newScenario);
    }
  }, [connectionTypes, editingScenario, realFilters, numLinks]);

  const elements: U21NetworkGraphElements = useMemo(
    () =>
      buildNodeData({
        numConnections,
        connectionTypes,
        filters: realFilters,
        viewOnly,
        customDataSettings,
        gbrFuzzyEnabled,
      }),
    [
      numConnections,
      connectionTypes,
      realFilters,
      viewOnly,
      customDataSettings,
      gbrFuzzyEnabled,
    ],
  );

  const addConnectionType = useCallback(
    (newConnectionType: string) => {
      const newScenario = cloneDeep(editingScenario);
      newScenario.parameters.link_type = [
        newConnectionType,
        ...(editingScenario.parameters.link_type ?? []),
      ];
      onChange(newScenario);
    },
    [editingScenario, onChange],
  );

  const removeConnectionType = useCallback(
    (existingConnection: string) => {
      const newScenario = cloneDeep(editingScenario);
      const links = (editingScenario.parameters.link_type as string[])?.filter(
        (val) => val !== existingConnection,
      );
      newScenario.parameters.link_type = [...links];
      // Remove the filters for this connection if it existed
      delete newScenario.embeddedFilters[existingConnection];
      onChange(newScenario);
    },
    [editingScenario, onChange],
  );

  const handleGraphClick = useCallback(
    (e: EventObject) => {
      const group = e.target?.group?.();
      const isShiftClick = e.originalEvent.shiftKey;
      if (group === 'nodes') {
        const node = e.target.data();
        if (node.id.startsWith('connection-')) {
          const {
            connectionType,
            canFilter,
            isOriginalConnection,
            fullConnectionName,
            canAddOrRemove,
          } = node;
          // Don't allow adding multiple connections that can't be filtered, cause that doesn't make sense
          if (isShiftClick && canAddOrRemove && !viewOnly) {
            const currentCount: number =
              connectionTypeCounts[connectionType] ?? 1;
            if (isOriginalConnection) {
              addConnectionType(`${connectionType}:${currentCount}`);
              setConnectionTypeCounts({
                ...connectionTypeCounts,
                ...{ [connectionType]: currentCount + 1 },
              });
            } else {
              removeConnectionType(fullConnectionName);
            }
          } else if (canFilter) {
            // Default to showing the filtering
            setSelectedNode({
              connectionType,
              fullName: fullConnectionName,
            });
          }
        } else if (node.id.startsWith('entity-')) {
          // Entity node
          const { fullConnectionName } = node;
          setSelectedNode({
            connectionType: 'entity',
            fullName: fullConnectionName,
          });
        }
      }
    },
    [addConnectionType, connectionTypeCounts, removeConnectionType, viewOnly],
  );

  return (
    <>
      {!viewOnly && (
        <>
          To add nodes, Shift+click on the node with a + to duplicate
          <br />
          To remove nodes, Shift+click on the node with a - to delete
        </>
      )}
      <U21NetworkGraph
        cytoscapeOptions={GRAPH_BASED_RULES_CYTOSCAPE_OPTIONS}
        layoutOptions={GBR_LAYOUT_OPTIONS}
        elements={elements}
        onLayoutEnd={onLayoutEnd}
        handleGraphClick={handleGraphClick}
        elementKeysToUpdate={GBR_ELEMENT_KEYS_TO_UPDATE}
      />
      {selectedNode && (
        <GraphNodeFilterEditorModal
          fullConnectionName={selectedNode.fullName}
          connectionType={selectedNode.connectionType}
          title={`Filter: ${selectedNode.fullName.replace('_', ' ')}`}
          viewOnly={viewOnly}
          embeddedFilters={realFilters}
          onEmbeddedFilterChanged={(fullConnectionName, val) => {
            const newScenario = cloneDeep(editingScenario);
            // Remove key all-together if we don't have an actual query or inclusion/exclusion tags or fuzzy matching set
            if (
              !val.raw_sql &&
              !val.exclusion_tag_names?.length &&
              !val.inclusion_tag_names?.length &&
              !val.grouping?.length &&
              !val.fuzzy_match_score
            ) {
              delete newScenario?.embeddedFilters?.[fullConnectionName];
            } else if (newScenario.embeddedFilters) {
              newScenario.embeddedFilters[fullConnectionName] = val;
            }
            onChange(newScenario);
          }}
          onClose={() => {
            setSelectedNode(undefined);
          }}
        />
      )}
    </>
  );
};
