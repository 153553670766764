import { get, post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import {
  NetworkAnalysisResponse,
  LinkedEntity,
} from 'app/modules/networkAnalysis/types';
import {
  ACCEPTED_LINK_TYPES,
  LINK_ANALYSIS_LINK_TYPES,
} from 'app/modules/networkAnalysis/constants';
import { useSelector } from 'react-redux';
import { selectNetworkAnalysisOnDeltaLake } from 'app/shared/featureFlags/selectors';
import { NETWORK_ANALYSIS_QUERY_KEYS } from 'app/modules/networkAnalysis/queries/keys';

type EntityLinksResponse = Record<
  string,
  { data: any; id1?: string; id2?: string }
>;

const transformEntityNetworkAnalysisResponse = (
  initialData: EntityLinksResponse,
  entityIdOptions:
    | {
        baseEntityPgId: string;
        isDeltaLake: false;
      }
    | {
        baseEntityExternalId: string;
        isDeltaLake: true;
      },
): NetworkAnalysisResponse => {
  let baseEntityId: string;
  if (entityIdOptions.isDeltaLake) {
    const { baseEntityExternalId } = entityIdOptions;
    baseEntityId = Object.values(initialData).find(
      ({ data }) => data?.external_id === baseEntityExternalId,
    )?.data.external_id;
  } else {
    baseEntityId = entityIdOptions.baseEntityPgId;
  }
  // shouldn't be possible
  if (!baseEntityId) {
    throw new Error('Base entity ID is required');
  }
  const unseenEntities: { id: string; missingLink: string }[] = [];
  const transformedResponse = Object.values(
    initialData,
  ).reduce<NetworkAnalysisResponse>(
    (acc, obj) => {
      const {
        data: { object_type: objectType, type },
      } = obj;
      if (obj.data.id === null) {
        return acc;
      }
      if (objectType === 'NODE') {
        const {
          base_node: ignore1,
          node_id: ignore2,
          object_type: ignore3,
          id,
          ...rest
        } = {
          ...(obj.data as LinkedEntity),
          links: [],
          base_node: '', // For TS-2525
          node_id: '',
          object_type: '',
        };
        acc.entities[obj.data.id] = { ...rest, id: id.toString() };
      } else if (ACCEPTED_LINK_TYPES.has(type)) {
        const {
          id1,
          id2,
          data: { total, sent },
        } = obj;
        if (type === LINK_ANALYSIS_LINK_TYPES.TRANSACTION) {
          const txnId = `Transaction_${id1}_${id2}`;
          let entityId = id1?.replace('$ENTITY_', '') ?? '';
          if (entityId === baseEntityId) {
            entityId = id2?.replace('$ENTITY_', '') ?? '';
          }
          const amount = total * (sent ? -1 : 1);
          acc.transactions[txnId] = {
            id: txnId!,
            amount,
            entity: entityId,
          };
        } else {
          const linkId = id2;
          const entityId = id1?.replace('$ENTITY_', '') ?? '';
          const linkVal: string | number =
            linkId?.replace(
              `${
                type === LINK_ANALYSIS_LINK_TYPES.INSTRUMENT
                  ? '$INSTRUMENT'
                  : type
              }_`,
              '',
            ) ?? '';
          const entities = baseEntityId === entityId ? [] : [entityId];
          if (!acc.links[linkId!]) {
            acc.links[linkId!] = {
              id: linkId!,
              type,
              value: linkVal,
              entities,
            };
          } else if (baseEntityId !== entityId) {
            acc.links[linkId!].entities.push(entityId);
          }
          if (acc.entities[entityId]) {
            acc.entities[entityId].links.push(linkId!);
          } else {
            unseenEntities.push({ id: entityId, missingLink: linkId! });
          }
        }
      }
      return acc;
    },
    { links: {}, entities: {}, transactions: {} },
  );

  for (const { id, missingLink } of unseenEntities) {
    transformedResponse.entities[id]?.links.push(missingLink);
  }
  return transformedResponse;
};

const fetchEntityNetworkAnalysisLegacy = async (entityId: string) => {
  const response = await get<EntityLinksResponse>(
    `/entities/transaction-network/${entityId}`,
  );
  return transformEntityNetworkAnalysisResponse(response, {
    baseEntityPgId: entityId,
    isDeltaLake: false,
  });
};

const fetchEntityNetworkAnalysisDeltaLake = async (
  entityExternalID: string,
) => {
  const response = await post<EntityLinksResponse>(
    `/entities/network-analysis`,
    { entity_external_id: entityExternalID },
  );
  return transformEntityNetworkAnalysisResponse(response, {
    baseEntityExternalId: entityExternalID,
    isDeltaLake: true,
  });
};

export const useNetworkAnalysis = (
  entityId: string,
  entityExternalID: string,
) => {
  const linkAnalysisOnDeltaLake = useSelector(selectNetworkAnalysisOnDeltaLake);
  return useQuery({
    queryKey: NETWORK_ANALYSIS_QUERY_KEYS.getUseNetworkAnalysisKey(
      entityId,
      entityExternalID,
      !!linkAnalysisOnDeltaLake,
    ),
    queryFn: () =>
      linkAnalysisOnDeltaLake
        ? fetchEntityNetworkAnalysisDeltaLake(entityExternalID)
        : fetchEntityNetworkAnalysisLegacy(entityId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
