// Types
import { AlertType } from 'app/modules/alerts/types';

// Models
import { TableConfig } from 'app/shared/pagination/models';
import {
  AlertSummary,
  AlertDetails,
  AlertQueue,
  QueueSubtypes,
} from 'app/modules/alerts/models';
import { QueueAccessType, QueueType } from 'app/modules/queues/models';

// Constants
import { DEFAULT_ID } from 'app/shared/constants';
import { QueueDetailsResponse } from 'app/modules/queues/responses';
import { MOCK_ALERT_FORMATTED_DATA } from 'app/modules/dataSettings/mocks/mockFormattedDataResponse';

export const FLAGGED_AT_DATETIME_FORMAT = 'D MMM YYYY, ddd, LT';

export const ALERT_TABLE_DEADLINE_CONFIG: TableConfig = {
  key: 'due-date',
  label: 'Due Date',
  type: 'datetime',
  sortable: false,
};

export const INITIAL_ALERT_SUMMARY: AlertSummary = {
  id: -1,
  org_id: -1,
  alert_type: AlertType.TM,
  assigned_to_id: -1,
  title: '',
  'queue.title': '',
  description: '',
  created_at: '',
  external_id: '',
  status: 'OPEN',
  disposition: '',
  closing_action_trigger_id: -1,
  tags: [],
  start_date: '',
  end_date: '',
  dispositioned_at: '',
  last_comment: null,
  org_name: '',
  rules: [],
  dispositioned_by: null,
  amount_volume: 0,
  total_events: 0,
  num_txns: 0,
  queue_id: null,
  'assigned_to.full_name': null,
  work_started_on: '',
  assigned_to_name: null,
  object_source: '',
  created_by: -1,
  created_by_display_name: '',
  subdispositions: null,
  disposition_notes: null,
  queue_access_type: QueueAccessType.OPEN,
  formatted_data: MOCK_ALERT_FORMATTED_DATA,
};

export const INITIAL_ALERT: AlertDetails = {
  queue_id: -1,
  alert_type: AlertType.TM,
  num_alerts: 0,
  num_user_entities: 0,
  num_business_entities: 0,
  assigned_to_id: -1,
  assigned_to_name: null,
  closing_action_trigger_id: -1,
  created_at: '',
  description: '',
  events: [],
  entities: [],
  paginatedEntities: {
    entities: [],
    count: 0,
  },
  id: -1,
  org_id: -1,
  org_name: '',
  tags: [],
  rules: [],
  status: 'CLOSED',
  title: '',
  'queue.title': '',
  txn_histogram: {},
  instruments: [],
  associatedAlerts: [],
  event_resolutions: {},
  action_event_resolutions: {},
  num_instruments: 0,
  entity_resolutions: {},
  amount_volume: 0,
  deadline: undefined,
  txn_instrument_resolutions: {},
  attachments: [],
  disposition: '',
  subdispositions: [],
  disposition_notes: null,
  case_ids: [],
  sar_ids: [],
  dispositioned_at: '',
  custom_data: {},
  last_comment: null,
  dispositioned_by: null,
  total_events: 0,
  num_txns: 0,
  'assigned_to.full_name': null,
  work_started_on: '',
  start_date: '',
  end_date: '',
  object_source: '',
  external_id: '',
  watchers: [],
  content: {},
  num_entities: 0,
  comments: [],
  editors: [],
  investigation_validations: [],
  is_investigation_validated: false,
  investigation_data: {},
  audit_trail: [],
  created_by: -1,
  created_by_display_name: '',
  action_events: {
    count: 0,
    events: [],
  },
  queue_access_type: QueueAccessType.OPEN,
  formatted_data: MOCK_ALERT_FORMATTED_DATA,
};

export const INITIAL_ALERT_QUEUE: AlertQueue = {
  id: DEFAULT_ID,
  title: '',
  description: '',
  org_id: DEFAULT_ID,
  unit_id: DEFAULT_ID,
  org_default_queue: true,
  custom_checklist_id: null,
  custom_checklist: null,
  teams: [],
  rules: [],
  open_articles: 0,
  unassigned_count: 0,
  workflows: [],
  sort_by: '',
  created_at: '',
  created_by_full_name: '',
  high_threshold: null,
  low_threshold: null,
  type: QueueType.ALERT_QUEUE,
  access_type: QueueAccessType.OPEN,
};

export const INITIAL_ALERT_QUEUE_DETAILS: QueueDetailsResponse = {
  id: DEFAULT_ID,
  title: '',
  description: '',
  org_id: DEFAULT_ID,
  unit_id: DEFAULT_ID,
  org_default_queue: true,
  custom_checklist_id: null,
  custom_checklist: null,
  teams: [],
  rules: [],
  open_articles: 0,
  unassigned_count: 0,
  workflows: [],
  sort_by: '',
  created_at: '',
  created_by_full_name: '',
  high_threshold: null,
  low_threshold: null,
  type: QueueType.ALERT_QUEUE,
  access_type: QueueAccessType.OPEN,
  subtype: QueueSubtypes.TM,
  is_auto_run_ai_agent: false,
};

export const DEFAULT_UNASSIGNED_TO_RETRIEVE_COUNT = 1;

export const FORMAT_ALERT_QUEUE_SORT_BY = {
  HIGHEST_TRANSACTION_VALUE: 'Highest transaction value',
  OLDEST_CREATION_DATE: 'Oldest creation date',
  HIGH_RISK_SCORE: 'Highest risk score',
};

export const ALERT_TYPE_LABELS: {
  [key in AlertType]: string;
} = {
  [AlertType.TM]: 'TM',
  [AlertType.KYC]: 'KYC',
  [AlertType.CHAINALYSIS]: 'Chainalysis',
  [AlertType.DARK_WEB]: 'Dark web',
  [AlertType.CAR]: 'CAR',
  [AlertType.MANUAL]: 'Manual',
  [AlertType.WATCHLIST]: 'Watchlist',
};

export const ALERT_SCORE_TOOLTIP =
  'Alert Scores represent the severity of an alert according to a machine learning model trained on your previous alert actions. A high score represents an urgent alert. To learn more, please refer to our documentation.';

export const ALERT_SCORE_TOOLTIP_RISK =
  'Our model indicates this alert should be treated with a high priority due to its high alert score. To learn more, please refer to our documentation.';

export const ALERT_SCORE_TOOLTIP_NO_RISK =
  'Alert Scores represent the severity of an alert according to a machine learning model trained on your previous alert actions. To learn more, please refer to our documentation.';

export const DEFAULT_ALERT_QUEUES_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'title',
    type: 'queue_title',
    label: 'Queue Name',
  },
  {
    key: 'description',
    type: 'text',
    label: 'Description',
  },
  { key: 'open_alerts', type: 'label', label: 'Open Alerts', sortable: false },
  { key: 'rules', type: 'text', label: 'Rules', sortable: false },
  { key: 'teams', type: 'teams', label: 'Teams', sortable: false },
  { key: 'subtype', type: 'label', label: 'Type' },
];
