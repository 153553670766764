import {
  GetAlertsGroupedByObjectPayload,
  GetAlertsGroupedByObjectExpensivePayload,
} from 'app/modules/alerts/types/objectCentricInvestigation';
import {
  GetAlertsPayload,
  RetrieveAlertEntitiesPayload,
} from 'app/modules/alerts/models';
import { GetTransactionsPayload } from 'app/modules/transactions/requests';
import { PaginationPayload } from 'app/shared/pagination/models';

const BASE = ['alerts'] as const;
export const ALERT_QUERY_KEYS = {
  getAlert: (payload: number) => [...BASE, 'alert', payload] as const,
  getAlerts: (payload: GetAlertsPayload) =>
    [...BASE, 'alerts', payload] as const,
  getAlertsCount: (payload: GetAlertsPayload) =>
    [...BASE, 'count', payload] as const,
  getAlertsGroupedByObject: (
    payload:
      | GetAlertsGroupedByObjectPayload
      | GetAlertsGroupedByObjectExpensivePayload,
  ) => [...BASE, 'grouped', payload] as const,
  getAlertDispositionByAlertIds: (alertIds: number[]) => {
    return [
      'alert-disposition',
      'getAlertDispositionByAlertIds',
      alertIds,
    ] as const;
  },
  getAlertEntities: (payload: RetrieveAlertEntitiesPayload) =>
    [...BASE, 'entities', payload] as const,
  getAlertTransactions: (id: number, payload: GetTransactionsPayload) =>
    [...BASE, 'transactions', id, payload] as const,
  getAlertActionEvents: (id: number, payload: PaginationPayload) =>
    [...BASE, 'action-events', id, payload] as const,
  getAlertAmount: (id: number) => [...BASE, 'amount', id] as const,
  getAlertGraphRuleMatches: (id: number) =>
    [...BASE, 'entity-link-counts-matches', id] as const,
  getAlertPriorActivity: (id: number) =>
    [...BASE, 'prior-activity', id] as const,
};
