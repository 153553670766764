import {
  PullBasedDataFile,
  PullBasedDataFileStatus,
  Stream,
} from 'app/modules/pullBasedDataFiles/responses';
import { AsyncStatus } from 'app/shared/types/utils/asyncStatus';
import { PaginationPayload } from 'app/shared/pagination/models';

interface PullBasedServerState {
  count: number;
  pullBasedDataFiles: PullBasedDataFile[];
}

export type PullBasedDataFilesTableState = AsyncStatus<PullBasedServerState> &
  Pick<PaginationPayload, 'limit' | 'offset'>;

type TopLevelModalState = {
  modalStatus: 'NONE' | 'ADD_STREAM' | 'DEBUG_CONSENT' | 'DIRECT_UPLOADS';
};

type StreamModalState = {
  modalStatus: 'EDIT_STREAM' | 'DELETE_STREAM';
};

type StreamModalStateWithDataFilesPayload = {
  modalStatus:
    | 'ADD_FILES_TO_STREAM'
    | 'BULK_PROCESS_FILES'
    | 'BULK_DELETE_FILES';
  skip?: boolean;
};

type DataFileModalStates = {
  modalStatus: 'PREVIEW' | 'PROCESSING_ERRORS';
  file: PullBasedDataFile;
};

export type ModalState =
  | TopLevelModalState
  | DataFileModalStates
  | StreamModalState
  | StreamModalStateWithDataFilesPayload;

export interface DeletePullBasedDataFileAPIInfo {
  fileId: number;
  streamId: number;
}

export interface PullBasedDataFilesState {
  streams: Stream[];
  uploadingFlatFilesByStreamId: Record<
    Stream['id'],
    Record<string, UploadingFlatFileProgress>
  >;
  dataFilePagesByStreamId: Record<string, PullBasedDataFilesTableState>;
  modalState: ModalState;
  selectedStream?: Stream;
  selectedDatafile?: PullBasedDataFile;
}

// When formatting the status, we can derive these new statuses
export enum AdditionalPullBasedDatafileStatus {
  VALIDATING = 'VALIDATING',
  VALIDATED = 'VALIDATED',
  REAPED = 'VALIDATING',
}

export type FormattedPullBasedDatafileStatus =
  | PullBasedDataFileStatus
  | AdditionalPullBasedDatafileStatus;

export type UploadingFlatFileProgress = {
  fileInfo: {
    path: string;
    size: number;
  };
  progress: number;
  status: 'uploading' | 'processing' | 'done' | 'failed';
  errorMessage?: string;
};

export enum StreamSortValues {
  CREATED_AT_LATEST = 'created_at_latest',
  CREATED_AT_OLDEST = 'created_at_oldest',
  UPDATED_AT_LATEST = 'updated_at_latest',
  UPDATED_AT_OLDEST = 'updated_at_oldest',
  A_TO_Z = 'a_to_z',
  Z_TO_A = 'z_to_a',
}

export interface DataFilesTableState extends PaginationPayload {
  sort_column: 'file_name' | 'date_created' | 'file_size';
  sort_direction: 'descending' | 'ascending';
}

export enum DatafileStatusFilter {
  ADDED = 'ADDED',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
  FINISHED_WITH_FAILURES = 'FINISHED_WITH_FAILURES',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}
