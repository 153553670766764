// Types
import { FullRuleResponse, ShortRuleResponse } from 'app/modules/rules/types';
import { ShortTxnResponse } from 'app/modules/transactions/types';

// Models
import { AuditTrailSummary } from 'app/shared/auditTrail/models';
import { FileExports } from 'app/modules/fileExportsOld/models';
import { Transaction } from 'app/shared/events/models';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';
import { AlertDetails, AlertSummary } from 'app/modules/alerts/models';
import { DropdownItemProps } from 'semantic-ui-react';
import { ImmutableTree, JsonGroup } from 'react-awesome-query-builder';
import { AnyObjectType } from 'app/shared/models';
import { PaginationPayload } from 'app/shared/pagination/models';
import { EntitySummary } from 'app/modules/entities/models';
import { HistogramData } from 'app/modules/transactions/models';

// ScenarioWidgets
import mockWhitelistEntities from 'app/modules/rules/tests/mock-whitelist-entities';
import mockUploadBlacklists from 'app/modules/rules/tests/mock-upload-blacklists';
import { ShortTxnInstrumentResponse } from 'app/modules/txnInstruments/types';
import { ScenarioParameters } from 'app/modules/detectionModels/models';

// typeofs
export type WhitelistEntities = typeof mockWhitelistEntities;
export type UploadBlacklists = typeof mockUploadBlacklists;

export interface ScenarioConfigFilterOptions {
  [key: string]: boolean;
}

// constants to check validation for rule builder
export enum ScenarioConstants {
  NUMERIC_COMPARISON = '$numeric_comparison',
  // we are in the process of migrating $numeric_comparison to the BE and need to add this separate var without the $ for validation purposes
  NUMERIC_COMPARISON_REFACTOR = 'numeric_comparison',
  TIME_WINDOW = '$time_window',
  SIMPLE_SEQUENCE = 'simple_sequence',
  SIMPLE_BLACKLIST = 'simple_blacklist',

  // widget names
  BLACKLIST_SELECTOR = 'blacklist_selector',
  SEQUENCE_CODE_EDITOR = 'sequence_code_editor',
  PER_FIELD_BLACKLIST_EDITOR = 'per_field_blacklist_editor',
  COMPONENT_RULES_SELECTOR = 'component_rules_selector',
  PER_FIELD_BLACKLIST_EDITOR_WONOLO = 'per_field_blacklist_editor_wonolo',
  LINK_ANALYSIS_MOCK = 'link_analysis_mock',

  // field names
  BLACKLIST_ID = 'blacklist_id',
  SEQUENCE_CONTENT = 'sequence_content',
  MATCH_CONDITIONS = 'match_conditions',
  COMPONENT_RULE_IDS = 'component_rule_ids',
  MATCH_CONDITIONS_VALIDATION_RESULT = 'match_conditions_validated',
  CONSORTIUM_DYNAMIC_CONDITION_VALID = 'consortium_dynamic_condition_valid',
  CHECK_FRAUD_CONTENT_VALID = 'check_fraud_content_valid',
  ACH_FRAUD_CONTENT_VALID = 'ach_fraud_content_valid',
  ACH_RISK_SCORE_CONTENT_VALID = 'ach_risk_score_content_valid',
  ACH_CONSORTIUM_CONTENT_VALID = 'ach_consortium_content_valid',
  ACH_MISMATCHED_CONTENT_VALID = 'ach_mismatched_content_valid',
  ACH_DUPLICATE_CONTENT_VALID = 'ach_duplicate_content_valid',
  WATCHLIST = 'watchlist',
}

export const NUMERIC_COMPARISON_MIN_FIELDS = {
  'number|gt': 3,
  'number|ge': 3,
  'number|lt': 3,
  'number|le': 3,
  'number|eq': 3,
  'number|ne': 3,
  'number|between': 4,
  'dollar|gt': 3,
  'dollar|ge': 3,
  'dollar|lt': 3,
  'dollar|le': 3,
  'dollar|eq': 3,
  'dollar|ne': 3,
  'dollar|between': 4,
  'percent|gt': 3,
  'percent|ge': 3,
  'percent|lt': 3,
  'percent|le': 3,
  'percent|eq': 3,
  'percent|ne': 3,
  'percentile|gt': 2,
  'standard_deviation|gt': 3,
  'standard_deviation|ge': 3,
  'standard_deviation|lt': 3,
  'standard_deviation|le': 3,
  'standard_deviation|eq': 3,
  'standard_deviation|ne': 3,
};

export type RuleSources = 'INTERNAL' | 'EXTERNAL';

export enum RulesActionTypes {
  RETRIEVE_RULES = '@@rules/RETRIEVE_RULES',
  RETRIEVE_RULES_SUCCESS = '@@rules/RETRIEVE_RULES_SUCCESS',

  RETRIEVE_COMPONENT_RULES = '@@rules/RETRIEVE_COMPONENT_RULES',
  RETRIEVE_COMPONENT_RULES_SUCCESS = '@@rules/RETRIEVE_COMPONENT_RULES_SUCCESS',

  CLEAR_RULES = '@@rules/CLEAR_RULES',

  SET_DYNAMIC_COLUMN_DEFINITION_ID = '@@rules/SET_DYNAMIC_COLUMN_DEFINITION_ID',

  SET_EDITING_RULE = '@@rules/SET_EDITING_RULE',

  SET_ADDITIONAL_FIELDS_IN_RULE_PARAMETERS = '@@rules/SET_ADDITIONAL_FIELDS_IN_RULE_PARAMETERS',

  DEPLOY_SHADOW_RULE = '@@rules/DEPLOY_SHADOW_RULE',
  DEPLOY_SHADOW_RULE_SUCCESS = '@@rules/DEPLOY_SHADOW_RULE_SUCCESS',

  RETRIEVE_TRIGGERED_ALERTS = '@@rules/RETRIEVE_TRIGGERED_ALERTS',
  RETRIEVE_TRIGGERED_ALERTS_PENDING = '@@rules/RETRIEVE_TRIGGERED_ALERTS_PENDING',
  RETRIEVE_TRIGGERED_ALERTS_SUCCESS = '@@rules/RETRIEVE_TRIGGERED_ALERTS_SUCCESS',
  RETRIEVE_TRIGGERED_ALERTS_ERROR = '@@rules/RETRIEVE_TRIGGERED_ALERTS_ERROR',

  RETRIEVE_WHITELISTED_ENTITIES = '@@rules/RETRIEVE_WHITELISTED_ENTITIES',
  RETRIEVE_WHITELISTED_ENTITIES_SUCCESS = '@@rules/RETRIEVE_WHITELISTED_ENTITIES_SUCCESS',

  WHITELIST_ENTITIES_FOR_RULE = '@@rules/WHITELIST_ENTITIES_FOR_RULE',
  WHITELIST_ENTITIES_FOR_RULE_SUCCESS = '@@rules/WHITELIST_ENTITIES_FOR_RULE_SUCCESS',

  REMOVE_WHITELIST_ENTITIES_FOR_RULE = '@@rules/REMOVE_WHITELIST_ENTITIES_FOR_RULE',
  REMOVE_WHITELIST_ENTITIES_FOR_RULE_SUCCESS = '@@rules/REMOVE_WHITELIST_ENTITIES_FOR_RULE_SUCCESS',

  RETRIEVE_VALIDATION_RULES = '@@rules/RETRIEVE_VALIDATION_RULES',
  RETRIEVE_VALIDATION_RULES_SUCCESS = '@@rules/RETRIEVE_VALIDATION_RULES_SUCCESS',

  RETRIEVE_RULE_VALIDATION_ALERT = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT',
  RETRIEVE_RULE_VALIDATION_ALERT_SUCCESS = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT_SUCCESS',

  RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS',
  RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS_SUCCESS = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT_TRANSACTIONS_SUCCESS',

  RETRIEVE_RULE_VALIDATION_ALERT_ACTION_EVENTS = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT_ACTION_EVENTS',
  RETRIEVE_RULE_VALIDATION_ALERT_ACTION_EVENTS_SUCCESS = '@@rules/RETRIEVE_RULE_VALIDATION_ACTION_EVENTS_SUCCESS',

  RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES',
  RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES_SUCCESS = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES_SUCCESS',

  RETRIEVE_RULE_VALIDATION_ALERT_INSTRUMENTS_SUCCESS = '@@rules/RETRIEVE_RULE_VALIDATION_ALERT_INSTRUMENTS_SUCCESS',

  RETRIEVE_PAGINATED_ALERTS = '@@rules/RETRIEVE_PAGINATED_ALERTS',
  RETRIEVE_PAGINATED_ALERTS_SUCCESS = '@@rules/RETRIEVE_PAGINATED_ALERTS_SUCCESS',

  DELETE_VALIDATION_RULE = '@@rules/DELETE_VALIDATION_RULE',
  DELETE_VALIDATION_RULE_SUCCESS = '@@rules/DELETE_VALIDATION_RULE_SUCCESS',

  GLOBALWHITELIST_ENTITIES = '@@rules/GLOBALWHITELIST_ENTITIES',
  REMOVE_GLOBALWHITELIST_ENTITIES = '@@rules/REMOVE_GLOBALWHITELIST_ENTITIES',
  RETRIEVE_GLOBALWHITELIST_ENTITIES = '@@rules/RETRIEVE_GLOBALWHITELIST_ENTITIES',
  RETRIEVE_GLOBALWHITELIST_ENTITIES_SUCCESS = '@@rules/RETRIEVE_GLOBALWHITELIST_ENTITIES_SUCCESS',

  RETRIEVE_RULE_VALIDATION_FILE_EXPORTS = '@@rules/RETRIEVE_RULE_VALIDATION_FILE_EXPORTS',
  RETRIEVE_RULE_VALIDATION_FILE_EXPORTS_SUCCESS = '@@rules/RETRIEVE_RULE_VALIDATION_FILE_EXPORTS_SUCCESS',
}

export interface RuleParametersAdditionalFields {
  [key: string]: string;
}

export interface RetrieveRulesFilterPayload {
  tag_ids?: number[];
  rule_ids?: number[];
  phrase?: string;
  agent_ids?: number[]; // 'createdBy' rule filter
  created_start_date?: string; // 'createdStartDate' rule filter
  created_end_date?: string;
  statuses?: RuleStatus[];
  sources?: RuleSources[];
}

export type RetrieveRulesPayload = RetrieveRulesFilterPayload &
  PaginationPayload;

export interface RetrieveValidationRuleAlertsPayload extends PaginationPayload {
  id: string;
}

export interface RetrievedRulesModel {
  rules: RuleSummary[];
  count: number;
}

export interface AlertsById {
  [id: string]: AlertDetails;
}

export interface PaginatedAlerts {
  alerts: AlertSummary[];
  count: 0;
  total_alert_count?: number;
  total_hit_count?: number;
}

export interface RuleScheduleSummary {
  window_start: string;
  window_end: string;
  execution_type: 'DEFAULT' | 'MANUAL' | 'MAKEUP' | 'SCHEDULE' | 'UPLOAD';
}

export interface PaginatedSchedules {
  schedules: RuleScheduleSummary[];
  count: number;
  id: number;
}

export type SetEditingRulePayload = EditingRule;
export interface EditingRuleParameters extends Partial<ScenarioParameters> {
  additional_fields?: RuleParametersAdditionalFields;
  dynamic_column_definition_id?: number;
}

export interface EditingRule {
  metadata: RuleMetadata;
  embeddedFilters: {
    [scenarioParameter: string]: EditingRuleFiltersModel;
  };
  tags: number[];
  filters: EditingRuleFiltersModel;
  scenarioName: string | null;
  parameters: EditingRuleParameters;
  isValidated: boolean;
  duplicateRule: boolean;
  execution_frequency: string;
  queue: number | undefined;
  runs_on_org_id: number | undefined;
  visible_to_child_org: boolean;
}

export interface DeployShadowRulePayload {
  id: number;
}

export interface RetrieveWhitelistedEntitiesRequest extends PaginationPayload {
  id: number;
}

export interface Tags {
  selected?: number[];
  options: DropdownItemProps[];
  styles: StyleMap;
}

export interface RuleMetadata {
  title: string;
  description: string;
  customer_support_involved?: boolean;
  exec_start_date_time: string;
  exec_end_date_time: string;
  shadow_mode?: boolean;
  parentRuleId?: number;
  visible_to_child_org?: boolean;
  execution_delay_in_minutes?: number;
}

export interface BulkRuleDeployMetaData {
  exec_start_date_time: string;
  exec_end_date_time: string;
  shadow_mode: boolean;
  visible_to_child_org?: boolean;
}

export type NumericComparatorWording = 'more' | 'greater';

export type NumericComparatorOptions<T> = {
  [type in NumericComparatorWording]: T;
};

// the other keys will match the headers strings
export interface RuleHitModel {
  key: string;
  [otherKeys: string]: string;
}

export type ValidationResultStatus =
  | 'COMPLETE'
  | 'PENDING'
  | 'FAILED'
  | 'SPOOLED'
  | '';

export interface ValidationMetric {
  label: string;
  value: string | number;
  tooltip?: ValidationMetric[];
}

export interface ValidationResults {
  rule_validation_id: number;
  validation_histogram?: HistogramData;

  txns?: Transaction[];
  num_txns?: number;

  hits: RuleHit[];
  num_hits: number;

  displayingHitKey?: string;
  status?: string;

  metrics?: ValidationMetric[];
}

export interface RuleValidationAlertEntitiesPayload extends PaginationPayload {
  id: string | number;
}

export interface RuleValidationAlertEntitiesResponse {
  id: string | number;
  entities: EntitySummary[];
}
export interface RuleValidationAlertTransactionsPayload
  extends PaginationPayload {
  id: string | number;
}
export interface RuleValidationAlertTransactionsResponse {
  id: string | number;
  events: ShortTxnResponse[];
}

export interface RuleValidationAlertActionEventsResponse {
  events: ShortActionEventResponse[];
  count: number;
}

export interface RuleValidationAlertInstrumentsActionPayload {
  id: number;
  instruments: ShortTxnInstrumentResponse[];
}
export interface RuleFiltersModel {
  raw_sql: string;
  query_tree: JsonGroup;
  inclusion_tags: number[];
  exclusion_tags: number[];
  inclusion_tag_names?: string[];
  exclusion_tag_names?: string[];
  where_clause?: any;
  aggregate_query_tree: any;
}

export interface EditingRuleFiltersModel
  extends Omit<RuleFiltersModel, 'query_tree'> {
  query_tree: ImmutableTree;
  grouping?: string[];
  fuzzy_match_score?: number;
}

export interface RuleScenarioFilters {
  [parameterKey: string]: RuleFiltersModel;
}

export interface RuleEntitiesWhitelistPayload {
  rule_id: number;
  entity_external_ids: string[];

  alert_id?: number;
  duration?: number;
}

export interface RuleSummary extends ShortRuleResponse {}

// should mimic the RuleStatus enum in the rule.py model
export type RuleStatus =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'VALIDATION'
  | 'ACTIVE_SHADOW'
  | 'PENDING_ACTIVATION'
  | 'PENDING_SHADOW_ACTIVATION';

export interface RuleDetails extends FullRuleResponse {}

export interface RuleParameterOptionsWithValues {
  field_type: string;
  values: string[];
}

export interface RuleParameterOptionsWithOrgValues {
  field_type: string;
  org_values: string;
}

export interface DeactivateRuleResponse {
  id: number;
  audit_trail: AuditTrailSummary[];
}

export interface BulkDeleteModelsResponse {
  rule_ids: number[];
}

export interface RetrieveTriggeredAlertsResponse {
  alerts: Array<any>;
  count: number;
}

export interface EditRulePayload {
  id: number;
  title?: string;
  description?: string;
  tags?: number[];
  is_synchronous?: boolean;
  generates_alerts?: boolean;
  alert_queue?: number;
  alert_priority?: number | null;
  customer_support_involved?: boolean;
}

export type RuleHit = AnyObjectType;

export interface RuleValidationFileExportsResponse {
  file_exports: FileExports[];
  count: number;
}

export interface RulesState {
  validationRuleFileExports: RuleValidationFileExportsResponse;
  paginatedRules: RetrievedRulesModel;
  editingRule: EditingRule;
  currentRule: {
    rule: FullRuleResponse;
    paginated_alerts: { alerts: any[]; count: number };
    paginated_whitelisted_entities: WhitelistEntities;
    paginated_global_whitelisted_entities: {
      count: number;
      whitelisted_entities: any[];
    };
  };
  blacklists: UploadBlacklists[];
  validationRules: RetrievedRulesModel;
  componentRules: RetrievedRulesModel;
  alertsById: AlertsById;
  paginatedAlerts: PaginatedAlerts;
}
export interface BulkDeactivatePayload {
  rule_ids: number[];
}
