// stay in sync with pensieve/scenario_configs/entity_link_counts.json
// parameter_options.link_type.key_values
import { EntityGraphLink } from 'app/modules/detectionModels/components/scenarioWidgets/models';
import { startCase } from 'lodash';
import {
  CustomDataSettingsConfigResponse,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import { QueryTreeFiltersModel } from 'app/modules/detectionModels/models';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';

const CONNECTION_TYPE_LABELS: Partial<Record<EntityGraphLink, string>> = {
  phone: 'Phone Number',
  instrument: 'Instrument',
  client_fingerprint: 'Client Fingerprint',
  ssn: 'SSN',
  bank_account: 'Bank Account',
  entity_custom: 'Custom Field',
};

const formatGroupingAndSqlText = (
  existingFilter: QueryTreeFiltersModel | undefined,
  gbrFuzzyEnabled: boolean,
  configKey: Unit21DataClassifier | undefined,
  customDataSettings: Record<
    Unit21DataClassifier,
    CustomDataSettingsConfigResponse[]
  >,
) => {
  const groupingsStrings = existingFilter?.grouping?.map((group) => {
    const [groupName, customDataId] = group.split(':');
    if (!customDataId) {
      return groupName;
    }
    const config: CustomDataSettingsConfigResponse | undefined =
      configKey &&
      customDataSettings[configKey]?.find(
        (setting: CustomDataSettingsConfigResponse) =>
          setting.id.toString() === customDataId,
      );
    if (!config) {
      return 'Unknown custom data';
    }
    return keyPathToLabel(config, false, '.') || 'Unknown custom data';
  });
  const rawSqlQuery = existingFilter?.raw_sql
    ? `\n${existingFilter.raw_sql}`
    : '';

  const groupingMatchText = gbrFuzzyEnabled ? 'Exact match' : 'Match';
  const groupingText = groupingsStrings
    ? `\n${groupingMatchText} on: ${groupingsStrings}`
    : '';

  return `${rawSqlQuery}${groupingText}`;
};

const getFuzzyMatchValueInPctOrNull = (
  existingFilter: QueryTreeFiltersModel | undefined,
): string | undefined | null => {
  if (
    !existingFilter ||
    !existingFilter.fuzzy_match_score ||
    existingFilter.fuzzy_match_score === 1
  ) {
    return null;
  }
  return toPercent(existingFilter.fuzzy_match_score)?.toString();
};

export const toPercent = (value: number | undefined): number | null => {
  if (!value) return null;
  return Math.round(value * 100);
};

const formatFuzzyMatchText = (
  canFuzzyMatch: boolean,
  existingFilter: QueryTreeFiltersModel | undefined,
) => {
  if (!canFuzzyMatch) {
    return '';
  }
  const fuzzyMatchPct = getFuzzyMatchValueInPctOrNull(existingFilter);
  if (fuzzyMatchPct) {
    return `\n${fuzzyMatchPct}% fuzzy match`;
  }
  return `\nExact match`;
};

export const formatNodeLabel = (
  connectionType: EntityGraphLink,
  existingFilter: QueryTreeFiltersModel | undefined,
  configKey: Unit21DataClassifier | undefined,
  customDataSettings: Record<
    Unit21DataClassifier,
    CustomDataSettingsConfigResponse[]
  >,
  numText: string,
  canFuzzyMatch: boolean,
  gbrFuzzyEnabled: boolean,
): string => {
  const labelText =
    CONNECTION_TYPE_LABELS[connectionType] || startCase(connectionType);
  const groupingAndSqlText = formatGroupingAndSqlText(
    existingFilter,
    gbrFuzzyEnabled,
    configKey,
    customDataSettings,
  );

  const fuzzyLabel = formatFuzzyMatchText(canFuzzyMatch, existingFilter);
  return `${labelText}${numText}${groupingAndSqlText}${fuzzyLabel}`;
};
