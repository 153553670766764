import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import {
  U21Loading,
  U21Spacer,
  U21NoData,
  U21Button,
} from 'app/shared/u21-ui/components';

import { PriorAlerts } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorAlerts';
import { PriorCases } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorCases';
import { PriorSARs } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorSARs';

// Hooks
import { useGetAlertPriorActivity } from 'app/modules/alerts/queries/useGetAlertPriorActivity';

// Selectors
import { selectNewAlertDataAPIEnabled } from 'app/shared/featureFlags/selectors';
import { selectAlert } from 'app/modules/alerts/selectors';

export const PriorActivity = () => {
  const { pathname } = useLocation();
  const alertId: number | undefined = useMemo(() => {
    const pathId = Number(pathname.split('/')[2]);
    if (!isNaN(pathId)) {
      return pathId;
    }
    return undefined;
  }, [pathname]);
  const hasNewAlertDataAPI = useSelector(selectNewAlertDataAPIEnabled);
  const { data, isLoading, isError, refetch } = useGetAlertPriorActivity(
    alertId || -1,
  );
  const alert = useSelector(selectAlert);

  let associatedAlerts = data?.associated_alerts || [];
  let associatedCases = data?.associated_cases || [];
  let associatedSARs = data?.associated_sars || [];

  if (!hasNewAlertDataAPI) {
    // Fallback to old logic if new API is not enabled. Interface is updated to handle the new structure, this should be removed with the flag
    // eslint-disable-next-line @typescript-eslint/dot-notation
    associatedAlerts = alert['associated_alerts'] || [];
    // eslint-disable-next-line @typescript-eslint/dot-notation
    associatedCases = alert['associated_cases'] || [];
    // eslint-disable-next-line @typescript-eslint/dot-notation
    associatedSARs = alert['associated_sars'] || [];
  }

  if (isLoading) {
    return <U21Loading loading />;
  }

  if (isError) {
    return (
      <U21NoData>
        Failed to load prior activity. Please{' '}
        <U21Button color="primary" onClick={() => refetch()} variant="text">
          try loading
        </U21Button>{' '}
        again.
      </U21NoData>
    );
  }

  return (
    <U21Spacer spacing={2}>
      <PriorAlerts associatedAlerts={associatedAlerts} />
      <PriorCases associatedCases={associatedCases} />
      <PriorSARs associatedSARs={associatedSARs} />
    </U21Spacer>
  );
};
