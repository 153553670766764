import { isValidElement } from 'react';
import { createSelector } from 'reselect';

// Selectors
import {
  selectCustomChecklistDefinition,
  selectCustomChecklistHistory,
} from 'app/shared/customChecklist/selectors';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import {
  hasPermissionsHelper,
  selectSessionAgentPermissions,
} from 'app/modules/session/selectors';
import {
  selectAlert,
  selectHasValidAlert,
  selectIsIntuitAlert,
  selectAlertAssociatedEntities,
  selectAlertHasAssociatedEntities,
} from 'app/modules/alerts/selectors';
import { selectCustomConfigs } from 'app/shared/CustomConfig/selectors';

// Types
import {
  AdditionalFilters,
  AlertDetailsTab,
  AlertSource,
  AlertType,
  DisabledOnReason,
} from 'app/modules/alerts/types';
import { AlertSubtype } from 'app/modules/alerts/types/alert';

// Models
import { FeatureFlags } from 'app/shared/featureFlags/models';
import {
  CustomChecklistDefinition,
  CustomChecklistHistoricalSubmission,
} from 'app/shared/customChecklist/models';
import { AlertDetails } from 'app/modules/alerts/models';

// Helpers
import {
  getAlertTypeForCustomTabs,
  getDefaultTabObject,
} from 'app/modules/alerts/helpers';

// Constants
import {
  CONSORTIUM_HITS_TAB_PATH,
  CUSTOM_DATA_TAB_PATH,
} from 'app/modules/alerts/components/AlertDetails/Tabs/allTabs';
import { ALERT_SCORE_TAB } from 'app/modules/alertScore/constants';
import { ADVERSE_MEDIA_MATCHES_TAB_PATH } from 'app/modules/alerts/components/AlertDetails/Tabs/DefaultAlertTabs';
import { selectConsortiumEnabled } from 'app/modules/orgSettings/selectors';

type FiltersMap = Record<AdditionalFilters, boolean>;
const filtersToMap = (filters: AdditionalFilters[]): FiltersMap => {
  const result: FiltersMap = {
    [AdditionalFilters.NEEDS_CHECKLIST]: false,
    [AdditionalFilters.INTUIT]: false,
    [AdditionalFilters.INTUIT_ONLY]: false,
  };

  filters.forEach((filter) => {
    result[filter] = true;
  });

  return result;
};

const passesIntuitFilters = (
  isIntuitAlert: boolean,
  filters: FiltersMap,
): boolean => {
  const supportsIntuit = filters[AdditionalFilters.INTUIT];
  const supportsIntuitOnly = filters[AdditionalFilters.INTUIT_ONLY];

  const enabledForIntuit = supportsIntuit || supportsIntuitOnly;

  // If we're viewing an Intuit alert and the tab doesn't support Intuit alerts
  if (isIntuitAlert && !enabledForIntuit) {
    return false;
  }

  return isIntuitAlert || !supportsIntuitOnly;
};

const passesChecklistFilters = (
  checklistDefinition: CustomChecklistDefinition[] | undefined,
  checklistHistory: CustomChecklistHistoricalSubmission[],
  filters: FiltersMap,
): boolean => {
  const needsChecklist = filters[AdditionalFilters.NEEDS_CHECKLIST];

  // If we don't have a checklist definition and the tab requires it
  return (
    Boolean(checklistDefinition) ||
    Boolean(checklistHistory.length) ||
    !needsChecklist
  );
};

const passesPermissions = (
  allPermissions: string[],
  neededPermissions: string[],
): boolean => {
  // Nothing to check
  if (neededPermissions.length === 0) {
    return true;
  }

  return hasPermissionsHelper(allPermissions, neededPermissions);
};

const passesFeatureFlags = (
  flags: FeatureFlags,
  neededFlags: string[],
): boolean => {
  // Checking that every needed flag is enabled
  return neededFlags.every((neededFlag) => flags[neededFlag]);
};

const passesTypeCheck = (alert: AlertDetails, types: AlertType[]): boolean => {
  const alertType = alert.alert_type;

  // Checks that the types associated with this tab include the curent alert
  return types.includes(alertType);
};

export const selectDisabledReasons = createSelector(
  selectAlertAssociatedEntities,
  selectAlertHasAssociatedEntities,
  (
    associatedEntities,
    hasAssociatedEntities,
  ): Record<DisabledOnReason, boolean> => ({
    [DisabledOnReason.NO_NETWORK_ANALYSIS]:
      hasAssociatedEntities && associatedEntities.length === 0,
  }),
);

export const selectAlertDetailsTabs = (tabs: AlertDetailsTab[]) =>
  createSelector(
    selectAlert,
    selectHasValidAlert,
    selectIsIntuitAlert,
    selectCustomChecklistDefinition,
    selectCustomChecklistHistory,
    selectSessionAgentPermissions,
    selectFeatureFlags,
    selectDisabledReasons,
    selectConsortiumEnabled,
    (
      alert,
      hasValidAlert,
      isIntuitAlert,
      checklistDefinition,
      checklistHistory,
      allPermissions,
      featureFlags,
      disabledReasons,
      consortiumEnabled,
    ) => {
      // Don't filter or disable tabs unless we've got a valid alert fetched
      if (!hasValidAlert) {
        return tabs;
      }

      return tabs
        .filter((tab: AlertDetailsTab) => {
          if (isValidElement(tab)) {
            return tab;
          }

          const {
            types = [],
            flags = [],
            permissions = [],
            filters = [],
          } = tab.visibleOn;
          const filtersMap = filtersToMap(filters);

          if (!passesIntuitFilters(isIntuitAlert, filtersMap)) {
            return false;
          }

          if (
            !passesChecklistFilters(
              checklistDefinition,
              checklistHistory,
              filtersMap,
            )
          ) {
            return false;
          }

          if (!passesPermissions(allPermissions, permissions)) {
            return false;
          }

          if (!passesFeatureFlags(featureFlags, flags)) {
            return false;
          }

          if (
            tab.path === ADVERSE_MEDIA_MATCHES_TAB_PATH &&
            alert.alert_subtype !== AlertSubtype.ADVERSE_MEDIA
          ) {
            return false;
          }

          if (tab.path === ALERT_SCORE_TAB && !alert.alert_score) {
            return false;
          }

          // show custom data tab if manual or external alert
          if (tab.path === CUSTOM_DATA_TAB_PATH) {
            return (
              alert.alert_type === AlertType.MANUAL ||
              alert.object_source === AlertSource.EXTERNAL
            );
          }

          if (tab.path === CONSORTIUM_HITS_TAB_PATH && !consortiumEnabled) {
            return false;
          }

          return passesTypeCheck(alert, types);
        })
        .map((tab: AlertDetailsTab) => {
          if (isValidElement(tab)) {
            return tab;
          }

          if (
            tab.disabledOn?.some(
              (reasonToDisable) => disabledReasons[reasonToDisable],
            )
          ) {
            return {
              ...tab,
              disabled: true,
            };
          }
          return tab;
        });
    },
  );

export const selectCustomAlertTabsConfig = (
  associatedTabs: AlertDetailsTab[],
) =>
  createSelector(selectAlert, selectCustomConfigs, (alert, configs) => {
    // Returns an object for constant lookup
    const defaultAlertTabObject = getDefaultTabObject(associatedTabs);

    const customAlertTabType = getAlertTypeForCustomTabs(alert.alert_type);
    const customTabDefinition = configs[customAlertTabType]?.definition || [];

    // If there is no custom tab definition or right feature flag (to be removed), return the default tabs
    if (!customTabDefinition.length) {
      return associatedTabs;
    }

    // Helps us keep track of the remaining tabs that are not saved in custom configs in the BE
    let remainingCustomTabs = { ...defaultAlertTabObject };
    const customTabOrder = customTabDefinition.reduce<AlertDetailsTab[]>(
      (acc, tabConfig) => {
        const tabDetails: AlertDetailsTab =
          defaultAlertTabObject[tabConfig.key];

        // This is a function that excludes the current tabConfig.key
        const removeProperty = ({ [tabConfig.key]: _, ...rest }) => rest;

        if (tabDetails) {
          acc.push({ ...tabDetails, hidden: tabConfig.hidden });
          // We can invoke the function to remove the current tabConfig.key from the remainingCustomTabs object
          remainingCustomTabs = removeProperty(remainingCustomTabs);
        }
        return acc;
      },
      [],
    );

    return [...customTabOrder, ...Object.values(remainingCustomTabs)];
  });
